<script>
  import {
    data,
    startTime,
    timeRange,
    filteredData,
    hue1,
    showFish,
    endDays,
    startDays,
    interactive,
    animation,
    sensors,
  } from './stores.js'
  import { onMount } from 'svelte'
  import { fade } from 'svelte/transition'
  import Fish from './Fish.svelte'
  import FishCounter from './FishCounter.svelte'
  import FishRadial from './FishRadial.svelte'
  import SensorData from './SensorData.svelte'
  import { interval, timeout, timerFlush } from 'd3-timer'

  export let width
  export let height

  const margin = {
    top: 50,
    right: 10,
    bottom: 50,
    left: 10,
  }

  const w = width - margin.left - margin.right
  const h = height - margin.top - margin.bottom

  let svg

  function dayOfYear(date) {
    // const now = new Date()
    const start = new Date(date.getFullYear(), 0, 0)
    const diff =
      date -
      start +
      (start.getTimezoneOffset() - date.getTimezoneOffset()) * 60 * 1000

    const oneDay = 1000 * 60 * 60 * 24

    return Math.floor(diff / oneDay)
  }

  const keys = [
    { id: 'Sensor_Acidity', groupId: 0, itemId: 0 },
    { id: 'Sensor_Acidity_Red', groupId: 0, itemId: 1 },
    { id: 'Sensor_Acidity_Tmp', groupId: 0, itemId: 2 },
    { id: 'Sensor_Conductivity', groupId: 1, itemId: 0 },
    { id: 'Sensor_Conductivity_Sal', groupId: 1, itemId: 1 },
    { id: 'Sensor_Conductivity_Tmp', groupId: 1, itemId: 2 },
    { id: 'Sensor_Conductivity_TDS', groupId: 1, itemId: 3 },
    { id: 'Sensor_Oxygen', groupId: 2, itemId: 0 },
    { id: 'Sensor_Oxygen_Sat', groupId: 2, itemId: 1 },
    { id: 'Sensor_Oxygen_Tmp', groupId: 2, itemId: 2 },
    { id: 'Sensor_Waterlevel_H', groupId: 3, itemId: 0 },
    { id: 'Sensor_Waterlevel_L', groupId: 3, itemId: 1 },
    { id: 'Value_Flow_Speed', groupId: 4, itemId: 0 },
    { id: 'Value_Lurelight', groupId: 5, itemId: 0 },
  ]

  const ONE_DAY_IN_MS = 24 * 60 * 60 * 1000
  const ROTATE_SPEED_ANGLE_LIMIT = 0.8

  let bgHue = 180
  let hideInstructions = false

  $: mouseDown = false
  $: mouseMove = false
  $: previousMouse = { x: 0, y: 0 }
  $: mouse = { x: 0, y: 0 }

  $: angle = Math.atan2(
    mouse.y - (margin.top + h / 2),
    mouse.x - (margin.left + w / 2)
  )

  $: previousAngle = Math.atan2(
    previousMouse.y - (margin.top + h / 2),
    previousMouse.x - (margin.left + w / 2)
  )

  $: angleDiff =
    Math.abs(angle - previousAngle) > Math.PI - ROTATE_SPEED_ANGLE_LIMIT
      ? 0
      : angle - previousAngle

  let forward = true

  $: {
    // if (mouseDown) {
    let nextTime = 0
    if (angleDiff > 0) {
      nextTime = Math.round(
        $startTime +
          ONE_DAY_IN_MS /
            ((Math.PI * 2) /
              Math.min(ROTATE_SPEED_ANGLE_LIMIT, Math.abs(angleDiff)))
      )
    } else
      nextTime = Math.round(
        $startTime -
          ONE_DAY_IN_MS /
            ((Math.PI * 2) /
              Math.min(ROTATE_SPEED_ANGLE_LIMIT, Math.abs(angleDiff)))
      )

    if (nextTime < $startDays * 24 * 60 * 60 * 1000) {
      $startTime = $startDays * 24 * 60 * 60 * 1000
    } else if (nextTime > ($endDays - 1) * ONE_DAY_IN_MS) {
      $startTime = ($endDays - 1) * ONE_DAY_IN_MS
    } else {
      $startTime = nextTime
    }
    // }
  }

  const INCREMENT = 120000

  let t

  const animate = () => {
    forward =
      (forward && $startTime + INCREMENT < 10 * ONE_DAY_IN_MS) ||
      (!forward && $startTime - INCREMENT < 0)

    if (forward) {
      $startTime += INCREMENT
    } else {
      $startTime -= INCREMENT
    }
  }

  let point

  onMount(() => {
    if ($animation) {
      t = interval(animate)
    }

    point = svg.createSVGPoint()
  })

  function throttle(callback, limit) {
    var waiting = false
    return function () {
      if (!waiting) {
        callback.apply(this, arguments)
        waiting = true
        setTimeout(function () {
          waiting = false
        }, limit)
      }
    }
  }

  $: {
    if (!$animation && t) {
      t.stop()
    }
  }

  const handleMove = (e) => {
    if ($interactive) {
      point.x = e.clientX
      point.y = e.clientY
      point = point.matrixTransform(svg.getScreenCTM().inverse())
      hideInstructions = true
      previousMouse = mouse
      mouse = { x: point.x, y: point.y }
      if ($animation && t) {
        t.stop()
      }
    }
  }
</script>

<style>
  svg {
    max-width: 900px;
    max-height: 750px;
    z-index: 1000;
  }
</style>

<svg
  {width}
  {height}
  bind:this={svg}
  viewBox="0 0 {width}
  {height}"
  on:mousedown={() => {
    mouseDown = true
  }}
  on:mouseup={() => (mouseDown = false)}
  on:mousemove={throttle(handleMove, 10)}
  on:touchmove={throttle(handleMove, 10)}>
  {#if $data}
    <g transform="translate({margin.left}, {margin.top})">
      <g transform="translate({w / 2}, {h / 2})">
        {#if $interactive && $sensors.filter((d) => d).length !== 1 && !hideInstructions}
          <text
            class="instructions"
            y={20}
            dy={$sensors.filter((d) => d).length === 2 ? -150 : $sensors.filter((d) => d).length === 3 ? -180 : 0}
            in:fade={{ duration: 1500 }}
            out:fade={{ duration: 1500, delay: 5000 }}
            style="text-anchor: middle; dominant-baseline: middle; fill: white;">
            ··· M↻VE IN CIRCLES ···
          </text>
        {/if}
        <FishCounter />
        <FishRadial {width} {height} />
        <SensorData />
      </g>
    </g>
  {/if}
</svg>
