<script>
  import {
    data,
    startTime,
    timeRange,
    filteredData,
    hue1,
    showFish,
    endDays,
    startDays,
    animation,
    interactive,
    sensors,
  } from './stores.js'
  import { scaleTime, scaleSqrt, scaleLinear } from 'd3-scale'
  import { extent, group, range, shuffle, min, max } from 'd3-array'
  import { line } from 'd3-shape'
  import Svg from './Svg.svelte'
  import PasswordProtect from './PasswordProtect.svelte'
  import { onMount } from 'svelte'
  import { parentDimensions } from './parent-dimensions.js'

  let width = 900
  let height = 750

  const ONE_DAY_IN_MS = 24 * 60 * 60 * 1000

  function getHue(param, defaultHue) {
    if (!location.search) {
      return defaultHue
    }

    const params = location.search
      .replace('?', '')
      .split('&')
      .reduce((acc, cur) => {
        acc[cur.split('=')[0]] = +cur.split('=')[1]
        return acc
      }, {})

    if (!params) {
      return defaultHue
    }

    return +params[param] || defaultHue
  }

  let debug = false

  const getArray = (param, defaultParam) => {
    if (!location.search) {
      return defaultParam
    }

    const params = location.search
      .replace('?', '')
      .split('&')
      .reduce((acc, cur) => {
        acc[cur.split('=')[0]] = cur.split('=')[1]
        return acc
      }, {})

    return params.hasOwnProperty(param)
      ? params[param].split('').map((d) => Boolean(+d))
      : defaultParam
  }

  const getBoolean = (param, defaultParam) => {
    if (!location.search) {
      return defaultParam
    }

    const params = location.search
      .replace('?', '')
      .split('&')
      .reduce((acc, cur) => {
        acc[cur.split('=')[0]] = !!+cur.split('=')[1]
        return acc
      }, {})

    return params.hasOwnProperty(param) ? params[param] : defaultParam
  }

  const getInt = (param, defaultParam) => {
    if (!location.search) {
      return defaultParam
    }

    const params = location.search
      .replace('?', '')
      .split('&')
      .reduce((acc, cur) => {
        acc[cur.split('=')[0]] = +cur.split('=')[1]
        return acc
      }, {})

    return +params[param] || defaultParam
  }

  window.mobileCheck = function () {
    let check = false
    ;(function (a) {
      if (
        /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
          a
        ) ||
        /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
          a.substr(0, 4)
        )
      )
        check = true
    })(navigator.userAgent || navigator.vendor || window.opera)
    return check
  }

  onMount(() => {
    debug = getBoolean('debug', false)
    $animation = getBoolean('animation', true)
    $interactive = getBoolean('interactive', mobileCheck() ? false : true)
    $startDays = getInt('start', 0)
    $endDays = getInt('end', 3) + 1
    $sensors = getArray('sensors', [true, true, true, true])
  })

  // getHue('hue', 120)
  $: bghue = getHue('bghue', 180)
  $: $hue1 = getHue('hue', 120)
</script>

<style>
  :global(body) {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: Roboto, sans-serif;
  }

  :global(text) {
    letter-spacing: 3px;
    font-size: 8px;
    fill: #43ff00;
    user-select: none;
  }
</style>

<!-- use:parentDimensions={{ width: 900, height: 750 }} -->
<!-- style="background: radial-gradient(circle, hsl({bgHue}, 88%, 23%) 0%,
    #0c0f2a);" -->
<!-- <PasswordProtect> -->
<div
  id="viz"
  style=" display: flex; align-items: center; justify-content: center; height:
  100vh; background: radial-gradient(circle, hsl({bghue}, 88%, 23%) 0%,
  #0c0f2a); ">
  <canvas
    style="width: {width}px; height: {height}px; position: absolute; left: 50%;
    margin-left: {-width / 2}px;"
    id="back" />
  <Svg {width} {height} />
  <div
    style="background-color: #43ff00; position: absolute; bottom: 0; left: -3px;
    width: {(($startTime - $startDays * ONE_DAY_IN_MS) / (($endDays - 1) * ONE_DAY_IN_MS - ($startDays - 0) * ONE_DAY_IN_MS)) * 100}%;
    height: 3px;" />

  {#if debug}
    <!-- <div>
      <input
        bind:value={$timeRange}
        type="range"
        min={1000 * 60 * 60}
        max={ONE_DAY_IN_MS}
        style="width: 400px" />
    </div>
    <div>
      <input
        bind:value={$startTime}
        type="range"
        min={0}
        max={10 * ONE_DAY_IN_MS}
        style="width: 800px" />
    </div> -->
    <div style="position: absolute; bottom: 0; left: 0; color: white;">
      <div>
        <input
          bind:value={$hue1}
          type="range"
          min={0}
          max={360}
          style="width: 400px" />
        dots hue: {Math.round($hue1)}
      </div>
      <div>
        <input
          bind:value={bghue}
          type="range"
          min={0}
          max={360}
          style="width: 400px" />
        background hue: {Math.round(bghue)}
      </div>
    </div>
  {/if}
  <!-- <button on:click={() => ($showFish = !$showFish)}>
      {$showFish ? 'dots' : 'fish'}
    </button> -->
  <!-- {/if} -->
  <!-- </PasswordProtect> -->
</div>
