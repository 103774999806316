<script>
  import { tweened } from 'svelte/motion'
  import { cubicOut } from 'svelte/easing'
  import { onMount } from 'svelte'
  import { curveBasis, area } from 'd3-shape'
  import { startTime, hue1, showFish } from './stores.js'

  export let fish
  export let x
  export let y
  export let c
  // export let i
  export let length

  const p = tweened(0, {
    duration: 200,
    delay: length / 8,
    easing: cubicOut,
  })

  const f = area().curve(curveBasis)([
    [1, -1],
    [0, -2],
    [2, -1],
    [5, -2, 5],
    [7, -2],
    [9, -1],
    [7, 0],
    [1, -1],
    [0, 0],
    [1, -1],
  ])

  onMount(() => {
    $p = fish.direction !== 'leftright_topbottom' ? x(fish.size) : -x(fish.size)
  })

  $: r = Math.sqrt(fish.size / 20)
  $: hue = $hue1 + (fish.size / c.range()[1]) * 60
</script>

<style>
  /* line {
    mix-blend-mode: color-dodge;
    isolation: isolate;
  } */

  circle {
    stroke-width: 1;
    transform: translateZ(0); /* no effect?? */
    /* fill-opacity: 0.3; */
  }
</style>

<g transform="rotate({fish.angle}) translate(240, 0)">
  <!-- <line
    x1={fish.direction !== 'leftright_topbottom' ? 1 : -1}
    x2={$p}
    stroke="hsl({130 + (fish.size / c.range()[1]) * 60}, 50%, 50%)"
    opacity={0.5} /> -->
  <line
    x1={fish.direction !== 'leftright_topbottom' ? 1 : -1}
    x2={$p}
    stroke="hsl({hue}, 50%, 50%)"
    opacity={0.5} />
  {#if $p !== 0}
    <!-- <circle
      cx={Math.sqrt(fish.size / 20) + $p}
      r={Math.sqrt(fish.size / 20)}
      stroke="hsl({130 + (fish.size / c.range()[1]) * 60}, 50%, 50%)"
      fill="hsl({130 + (fish.size / c.range()[1]) * 60}, 50%, 50%)"
      style="stroke-width: 1; fill-opacity: 0.3;" /> -->

    <!-- {#if !$showFish} -->
    <circle
      cx={r + $p}
      {r}
      stroke="hsl({hue}, 50%, 50%)"
      fill="hsl({hue}, 50%, 30%)" />
    <!-- {:else}
      <path
        transform="translate({(fish.direction !== 'leftright_topbottom' ? 1 : -1) * 5 + $p},
        {fish.size / 100}) scale({((fish.direction !== 'leftright_topbottom' ? 1 : -1) * fish.size) / 100},
        {fish.size / 100})"
        d={f}
        fill="hsl({$hue1 + (fish.size / c.range()[1]) * 60}, 50%, 50%)" />
    {/if} -->
  {/if}
</g>
