<script>
  import { range, shuffle, extent, min } from 'd3-array'
  import { scaleLinear } from 'd3-scale'
  import { arc } from 'd3-shape'
  import { filteredData, data, startTime } from './stores.js'
  import Fish from './Fish.svelte'
  import FishCanvas from './FishCanvas.svelte'

  export let width
  export let height

  $: x = scaleLinear()
    .domain(extent($data.fish, (d) => d.size))
    .range([0, 80])

  const ONE_DAY_IN_MS = 24 * 60 * 60 * 1000

  const y = scaleLinear()
    .domain([0, 60 * 60 * 24])
    .range([0, 360])

  const y2 = scaleLinear()
    .domain([0, 60 * 60 * 24])
    .range([0, Math.PI * 2])

  $: c = scaleLinear()
    .domain(extent($data.fish, (d) => d.size))
    .range([0, 300])

  $: a = arc()
    .innerRadius(363)
    .outerRadius(365)
    .startAngle(0)
    .endAngle(
      ((($startTime +
        y2(
          $data.fish[0].timestamp.getSeconds() +
            $data.fish[0].timestamp.getMinutes() * 60 +
            $data.fish[0].timestamp.getHours() * 60 * 60
        )) %
        ONE_DAY_IN_MS) /
        ONE_DAY_IN_MS) *
        Math.PI *
        2
    )

  $: rotation =
    -90 +
    y($startTime / 1000) +
    y(
      $data.fish[0].timestamp.getSeconds() +
        $data.fish[0].timestamp.getMinutes() * 60 +
        $data.fish[0].timestamp.getHours() * 60 * 60
    )
</script>

<style>
  text {
    dominant-baseline: middle;
    text-anchor: middle;
  }

  line {
    stroke-width: 2;
    stroke-dasharray: 1 1;
    stroke: white;
  }

  circle {
    stroke-dasharray: 1 10;
    stroke: white;
    fill: none;
    opacity: 0.3;
  }
</style>

<g>
  <circle r={350} />
  <path d={a()} fill="rgba(255, 255, 255, 0.1)" />
  {#each range(0, 24, 2) as hour, i}
    <text
      x={Math.cos(-Math.PI / 2 + (y(hour * 60 * 60) * Math.PI) / 180) * 350}
      y={Math.sin(-Math.PI / 2 + (y(hour * 60 * 60) * Math.PI) / 180) * 350}>
      {hour}:00
    </text>
  {/each}
  <!-- {#each shuffle(data) as fish, i (fish.object_id)} -->
  <!-- <FishCanvas {width} {height} {x} {y} {c} /> -->
  {#each $filteredData.fish as fish, i (fish.object_id)}
    <Fish {x} {fish} {y} {c} length={$filteredData.fish.length} />
  {/each}
  <line x2="50" transform="rotate({rotation}) translate(320, 0)" />
  {#if $startTime > 100}
    <text
      transform="rotate({rotation}) translate(350, 0) rotate(90)"
      style="transform: rotateZ(360); text-anchor: end; fill: rgba(0, 0, 0,
      0.5); stroke: rgba(0, 0, 0, 0.5); stroke-width: 2;"
      dy={-21}
      dx={-5}>
      day {2 + Math.floor($startTime / ONE_DAY_IN_MS)}
    </text>
    <text
      transform="rotate({rotation}) translate(350, 0) rotate(90)"
      style="transform: rotateZ(360); text-anchor: end; fill: white;"
      dy={-21}
      dx={-5}>
      day {2 + Math.floor($startTime / ONE_DAY_IN_MS)}
    </text>
  {/if}
  <text
    transform="rotate({rotation}) translate(350, 0) rotate(90)"
    style="transform: rotateZ(360); text-anchor: start; fill: rgba(0, 0, 0,
    0.5); stroke: rgba(0, 0, 0, 0.5); stroke-width: 2;"
    dy={20}
    dx={10}>
    day {1 + Math.floor($startTime / ONE_DAY_IN_MS)}
  </text>
  <text
    transform="rotate({rotation}) translate(350, 0) rotate(90)"
    style="transform: rotateZ(360); text-anchor: start; fill: white;"
    dy={20}
    dx={10}>
    day {1 + Math.floor($startTime / ONE_DAY_IN_MS)}
  </text>
</g>
