import { csv, json } from 'd3-fetch'
import { readable, derived, writable } from 'svelte/store'
import { autoType } from 'd3-dsv'
import { extent, range, min } from 'd3-array'
import { scaleLinear } from 'd3-scale'

const MAX_DAYS = 11

const allSensors = [
  'Sensor_Acidity',
  'Sensor_Conductivity',
  'Sensor_Conductivity_Tmp',
  'Sensor_Oxygen',
]

export const data = readable(undefined, set => {
  Promise.all([
    csv('./data/fish.csv'),
    csv('./data/water.csv'),
    json('./data/datastream-30d.json'),
  ]).then(data => {
    let fish = data[0]
      .map(autoType)
      .filter(d => d.type === 'Vis')
      .filter(d => d.timestamp.getDate() > 10)

    let start = min(fish, d => d.timestamp.getTime())

    const a = scaleLinear()
      .domain([0, 60 * 60 * 24])
      .range([0, 360])

    fish = fish
      .map(d => ({
        ...d,
        t1: d.timestamp.getTime() - start,
        angle:
          -90 +
          a(
            d.timestamp.getSeconds() +
              d.timestamp.getMinutes() * 60 +
              d.timestamp.getHours() * 60 * 60
          ),
      }))
      .filter(d => d.t1 <= 11 * 24 * 60 * 60 * 1000)

    const dateExtent = extent(fish, d => d.timestamp.getTime())

    let sensor = data[2]
      .filter(d => d.locationId === '004' && allSensors.includes(d.seriesId))
      .map(d => ({
        ...d,
        dateTime: Date.parse(d.dateTime),
        value: +d.value,
      }))

    start = min(sensor, d => d.dateTime)

    sensor = sensor
      .map(d => ({
        ...d,
        t1: d.dateTime - start,
      }))
      .filter(d => d.t1 <= 11 * 24 * 60 * 60 * 1000)

    set({
      fish,
      water: data[1].map(autoType),
      sensor: sensor,
      // sensor: data[2].filter(d => d.locationId === '004'), // 001 is also an option
      dateExtent,
    })
  })
})

export const endDays = writable(3)
export const startDays = writable(0)
export const interactive = writable(true)
export const animation = writable(true)
export const timeRange = writable(24 * 60 * 60 * 1000)
export const startTime = writable(0 * 24 * 60 * 60 * 1000) // must be in sync with $startDays
export const hue1 = writable(120)
export const showFish = writable(false)
export const sensors = writable([true, true, true, true])

export const filteredData = derived(
  [data, timeRange, startTime, endDays, startDays, sensors],
  ([$data, $timeRange, $startTime, $endDays, $startDays, $sensors]) => {
    if (!$data) {
      return undefined
    }

    const fish = []
    for (let i = 0; i < $data.fish.length; i++) {
      const f = $data.fish[i]
      if (f.t1 >= $startTime && f.t1 <= $startTime + $timeRange) {
        fish.push(f)
      }
    }

    const activeSensors = $sensors.reduce(
      (acc, cur, i) => (cur ? acc.concat([allSensors[i]]) : acc),
      []
    )

    const sensors = []
    for (let i = 0; i < $data.sensor.length; i++) {
      const s = $data.sensor[i]
      if (
        s.t1 >= $startTime &&
        s.t1 <= $startTime + $timeRange &&
        activeSensors.includes(s.seriesId)
      ) {
        sensors.push(s)
      }
    }

    return {
      fish: fish.filter(
        d =>
          d.t1 >= $startDays * 24 * 60 * 60 * 24 &&
          d.t1 <= $endDays * 24 * 60 * 60 * 1000
      ),
      sensors: sensors.filter(
        d =>
          d.t1 >= $startDays * 24 * 60 * 60 * 1000 &&
          d.t1 <= $endDays * 24 * 60 * 60 * 1000
      ),
    }
  }
)
