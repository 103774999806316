<script>
  import { tweened } from 'svelte/motion'
  import { onMount } from 'svelte'
  import { filteredData, sensors } from './stores.js'

  let count = tweened(0, {
    duration: 200,
  })

  $: {
    count = tweened($count, {
      duration: 200,
    })

    $count = $filteredData.fish.length
  }
</script>

<style>
  text {
    font-size: 16px;
    text-anchor: middle;
    dominant-baseline: central;
  }
</style>

{#if $sensors.filter((d) => d).length !== 1}
  <text
    dy={$sensors.filter((d) => d).length === 2 ? -150 : $sensors.filter((d) => d).length === 3 ? -180 : 0}>
    {Math.round($count)} fish
  </text>
{/if}
