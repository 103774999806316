<script>
  import { group, extent, range } from 'd3-array'
  import { scaleLinear, scaleTime } from 'd3-scale'
  import { lineRadial, line } from 'd3-shape'
  import { filteredData, data, sensors } from './stores.js'

  // $: xs = scaleTime()
  //   .domain(extent($data.sensor, d => d.dateTime))
  //   .range([0, 10 * Math.PI * 2])

  const xs = scaleLinear()
    .domain([0, 24 * 60 * 60 * 1000])
    .range([0, Math.PI * 2])

  const xs2 = scaleLinear()
    .domain([0, 24 * 60 * 60 * 1000])
    .range([0, 360])

  // $: xs = scaleLinear()
  //   .domain(extent($filteredData.sensors, d => d.t1))
  //   .range([-150, 150])
  $: l = $sensors.filter((d) => d).length

  $: startAngle = l === 2 ? 0 : l === 3 ? Math.PI / 2 : 0
  $: innerRadius = l === 4 ? 120 : l === 3 ? 100 : l === 2 ? 100 : 0
  $: sensorRadius = l === 4 ? 65 : l === 3 ? 80 : l === 2 ? 90 : 170
  $: endAngle =
    l === 4
      ? Math.PI * 2
      : l === 3
      ? Math.PI * 2
      : l === 2
      ? Math.PI * 2
      : Math.PI * 2

  $: keys = [
    {
      index: 0,
      id: 'Sensor_Acidity',
      groupId: 0,
      itemId: 0,
      label: 'Acidity',
      unit: 'pH',
    },
    {
      index: 1,
      id: 'Sensor_Conductivity',
      groupId: 1,
      itemId: 0,
      label: 'Conductivity',
      unit: 'uSc',
    },
    {
      index: 2,
      id: 'Sensor_Conductivity_Tmp',
      groupId: 1,
      itemId: 2,
      label: 'Temperature',
      unit: '°C',
    },
    {
      index: 3,
      id: 'Sensor_Oxygen',
      groupId: 2,
      itemId: 0,
      label: 'Oxygen',
      unit: 'mg/L',
    },
  ].reduce((acc, cur) => {
    acc[cur.id] = {
      groupId: cur.groupId,
      itemId: cur.itemId,
      index: cur.index,
      label: cur.label,
      unit: cur.unit,
      y: scaleLinear()
        .domain(
          extent(
            $data.sensor.filter(
              (d) => d.seriesId === cur.id && d.t1 < 11 * 24 * 60 * 60 * 1000
            ),
            (d) => d.value
          )
        )
        .range([0, sensorRadius]),
    }

    return acc
  }, {})
</script>

<style>
  text {
    text-anchor: middle;
    dominant-baseline: central;
  }
</style>

<g>
  {#each Array.from(group($filteredData.sensors, (d) => d.seriesId)).sort((a, b) => keys[a[0]].index - keys[b[0]].index) as sensor, i}
    <g>
      <!-- <g>
        <circle
          r={90 + i * 30 + 10}
          fill="none"
          stroke="hsla(0, 50% ,100%, 0.05)"
          style="stroke-width: 20" />
        {#each range(3) as j}
          <circle
            r={90 + i * 30 + j * 10}
            fill="none"
            style="stroke-dasharray: 1 2;"
            stroke="rgba(255, 255, 255, 0.2)" />
        {/each}
        {#each sensor[1] as d}
          <circle
            r={1}
            fill="#43ff00"
            transform="rotate({90 - xs2(d.t1)}) translate({90 + i * 30 + keys[sensor[0]].y(d.value)},
            0)" />
        {/each}
        <path
          d={lineRadial()
            .angle((d) => xs(d.t1))
            .radius((d) => 90 + i * 30 + keys[sensor[0]].y(d.value))(sensor[1])}
          fill="none"
          style="stroke-width: 0.5;"
          stroke="#43ff00" />
      </g> -->
      <g
        transform="translate({Math.cos(startAngle + (i / l) * endAngle) * innerRadius},
        {Math.sin(startAngle + (i / l) * endAngle) * innerRadius})">
        {#each range(6) as i}
          <circle
            r={i * (sensorRadius / 5)}
            stroke="white"
            fill="rgba(255, 255, 255, 0.2)"
            style="stroke-dasharray: 1 2;"
            opacity={0.2} />
        {/each}
        <path
          d={lineRadial()
            .angle((d) => xs(d.t1))
            .radius((d) => keys[sensor[0]].y(d.value))(sensor[1])}
          fill="none"
          stroke="#43ff00" />
        <g
          transform="translate({Math.cos(xs(sensor[1][sensor[1].length - 1].t1) - Math.PI / 2) * keys[sensor[0]].y(sensor[1][sensor[1].length - 1].value)},
          {Math.sin(xs(sensor[1][sensor[1].length - 1].t1) - Math.PI / 2) * keys[sensor[0]].y(sensor[1][sensor[1].length - 1].value)})">
          <circle r={1.5} fill="#43ff00" />
          <circle r={3} fill="none" stroke="#43ff00" />
        </g>
      </g>
      <g
        transform="translate({Math.cos(startAngle + (i / l) * endAngle) * innerRadius},
        {Math.sin(startAngle + (i / l) * endAngle) * innerRadius})">
        <text
          dy={-sensorRadius - 10}
          fill="hsl({(keys[sensor[0]].groupId / 7) * 360},50%, 50%)">
          {keys[sensor[0]].label}
        </text>
        <text
          dy={sensorRadius + 10}
          fill="hsl({(keys[sensor[0]].groupId / 7) * 360},50%, 50%)">
          {sensor[1][sensor[1].length - 1].value}{keys[sensor[0]].unit}
        </text>
      </g>
    </g>
  {/each}
</g>
